
import { defineComponent } from 'vue'
import router from '@/router'
export default defineComponent({
  name: 'create-btn',
  setup() {
    const createLivedRoom = () => {
      // this.$EventBus.$emit('clearSidebarActiveIndex')
      router.push('/main/live-create-room')
    }
    return { createLivedRoom }
  },
})
