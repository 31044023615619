
import { defineComponent } from 'vue'

import LiveCardCopy from './cpns/live-card-copy.vue'

export default defineComponent({
  components: { LiveCardCopy },
  setup() {
    return {}
  },
})
