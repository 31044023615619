
import { defineComponent, ref, inject } from 'vue'
import { getLiveInfo, DeleteRoom } from '@/service/main/main'
import { liveStatus } from '../config/liveStatus'
import { MainHeader } from '@/components/main-header/index'
import { formatDay, formatStartOrEndTime } from '../config/time.config'
import router from '@/router'

import CreateBtn from './CreateBtn.vue'

export default defineComponent({
  components: { CreateBtn, MainHeader },
  props: {},
  async setup() {
    //刷新页面
    const reload: any = inject('reload')
    // 1.请求数据
    let pages = 10 //每页显示数量
    let currentPage = ref(0)
    let LiveInfoRes: any
    let LiveInfo = ref()
    let roomList: any
    let totalPage = ref()
    const liveResFun = async (currentPage = 0) => {
      LiveInfoRes = await getLiveInfo(currentPage, pages)
      LiveInfo.value = LiveInfoRes.room_info
      roomList = LiveInfoRes.room_info
      totalPage.value = Math.ceil(LiveInfoRes.total / pages) * 100
      console.log(totalPage.value)
    }

    liveResFun()

    // 分页事件
    const handleCurrentChange = async (val: any) => {
      currentPage.value = val
      liveResFun(val)
    }
    // 输入框改变
    let searchValue = ref('')

    const inputChange = (value: any) => {
      searchValue.value = value
      LiveInfo.value = roomList.filter((item: any) => {
        return (
          item.name.indexOf(value) !== -1 ||
          String(item.roomid).indexOf(value) !== -1 ||
          item.anchor_name.indexOf(value) !== -1
        )
      })
    }
    // 删除直播间
    const delLive = (event: any) => {
      const id = event.roomid
      DeleteRoom(id)
      reload()
    }

    // 跳转到控制台
    const toControl = (event: any) => {
      // router.push('/main/live-control')
      const { roomid } = event
      const { live_status } = event
      router.push({
        name: 'liveControl',
        query: {
          roomid,
          live_status,
        },
      })
    }

    return {
      LiveInfo,
      totalPage,
      searchValue,
      currentPage,
      liveStatus,
      formatDay,
      formatStartOrEndTime,
      inputChange,
      handleCurrentChange,
      delLive,
      toControl,
    }
  },
})
