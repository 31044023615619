const formatDay = (item: number) => {
  const date = new Date(item * 1000)
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${month}月${day}日`
}

const formatStartOrEndTime = (start: number, end: number) => {
  const startDate = new Date(start * 1000)
  const hours1 = startDate.getHours()
  let minutes1: number | string = startDate.getMinutes()
  minutes1 = minutes1 < 10 ? '0' + minutes1 : minutes1

  const endDate = new Date(end * 1000)
  const hours2 = endDate.getHours()
  let minutes2: number | string = endDate.getMinutes()
  minutes2 = minutes2 < 10 ? '0' + minutes2 : minutes2
  return `${hours1}:${minutes1}-${hours2}:${minutes2}`
}
export { formatDay, formatStartOrEndTime }
